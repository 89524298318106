import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import Drawer from "@material-ui/core/Drawer";
// import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import MenuIcon from "@material-ui/icons/Menu";
// import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/styles";

import "./Header.styl";
import Nav from "../Nav";
import LocalizedLink from "../../components/LocalizedLink";

const logo = require("../../../images/site/logo_red.svg");
const logoColored = require("../../../images/site/logo_colored.svg");

const CustomDrawer = withStyles(({ palette }) => ({
    paper: {
        backgroundColor: palette.primary.main
    }
}))(Drawer);

const Header = ({ langs }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [transparent, setTransparent] = useState(true);

    const toggleDrawer = open => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setIsOpen(open);
    };

    const headerColorChange = () => {
        const windowsScrollTop = window.pageYOffset;
        const content = document.querySelector(".page-content");
        const header = document.querySelector("header");

        const limit = content.offsetTop - header.offsetHeight * 2;

        if (windowsScrollTop > limit && transparent) {
            setTransparent(false);
        } else if (windowsScrollTop < limit && !transparent) {
            setTransparent(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", headerColorChange);

        return () => {
            window.removeEventListener("scroll", headerColorChange);
        };
    });

    return (
        <header id="header" className={cn({ colored: !transparent })}>
            <Fab
                // color="primary"
                color="primary"
                aria-label="Open drawer"
                onClick={toggleDrawer(true)}
                // edge="start"
                size="medium"
                className={cn("menuButton", { hide: isOpen })}
            >
                <MenuIcon />
            </Fab>

            <CustomDrawer open={isOpen} onClose={toggleDrawer(false)}>
                {/* <div className="nav-top" style={{ color: "white" }}>
                    <IconButton onClick={toggleDrawer(false)} color="inherit">
                        <CloseIcon />
                    </IconButton>
                </div> */}
                <Nav close={toggleDrawer} langs={langs} />
            </CustomDrawer>

            <div className="content">
                <LocalizedLink to="/" className="logo">
                    <img
                        src={transparent ? logo : logoColored}
                        alt="logo Groupe Editor"
                    />
                    {/* <div className="logo">
                    </div> */}
                </LocalizedLink>
            </div>
        </header>
    );
};

Header.propTypes = {
    langs: PropTypes.array.isRequired
};

export default Header;
