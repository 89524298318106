import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import "./Parallax.styl";
import ResponsiveBgImg from "../ResponsiveBgImg";

const Parallax = ({ children, img, overlay, blur, alt }) => {
    const [transform, setTransform] = useState("translate3d(0,0px,0)");

    const resetTransform = () => {
        const windowScrollTop = window.pageYOffset / 3;
        setTransform("translate3d(0," + windowScrollTop + "px,0)");
    };

    useEffect(() => {
        if (window.innerWidth >= 768) {
            const windowScrollTop = window.pageYOffset / 3;
            setTransform("translate3d(0," + windowScrollTop + "px,0)");
            window.addEventListener("scroll", resetTransform);
        }
        return () => {
            if (window.innerWidth >= 768) {
                window.removeEventListener("scroll", resetTransform);
            }
        };
    }, []);

    return (
        <ResponsiveBgImg
            className={cn("parallax", overlay, { blur })}
            style={{ transform: transform }}
            alt={alt}
            parallax
            {...img}
        >
            <div className="content">{children}</div>
        </ResponsiveBgImg>
    );
};

Parallax.defaultProps = {
    blur: false,
    overlay: "dark"
};

Parallax.propTypes = {
    children: PropTypes.node.isRequired,
    img: PropTypes.object.isRequired,
    alt: PropTypes.string.isRequired,
    blur: PropTypes.bool,
    overlay: PropTypes.string
};

export default Parallax;
