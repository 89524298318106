import ml from './pages/ml';

export default {
    general: {
        lang: 'langue',
        editor: 'Groupe Editor',
    },
    nav: {
        home: 'Accueil',
        card: 'Carterie',
        wrap: 'Emballage Cadeau',
        item: 'Objet Cadeau',
        group: 'Le Groupe',
        carrer: 'Carrières',
        contact: 'Contact',
        ml: 'Mentions Légales',
    },
    pages: {
        meta: {
            title: 'Groupe Editor',
            titleAlt:
                'Groupe Editor | Leader européen en carterie, emballage cadeau, papeterie et objets cadeaux',
            description: `Leader européen en carterie, emballage cadeau, papeterie et objets cadeaux`,
            author: `@JimmyBeldone`,
        },
        home: {
            headerTitle: 'Accueil',
            metaDescription:
                'Créateur, imprimeur et distributeur auprès des professionnels, Editor est leader européen en carterie, emballage cadeau, papeterie et objets cadeaux',
            title: 'Accueil',
            catch: "De l'art, de l'esprit, de l'unique",
            description:
                "Leader en Europe Continentale sur les métiers de la carterie et de l'emballage cadeau",
            landing: {
                title: 'Découvrez nos produits',
                card: {
                    '1': 'Carterie',
                    '2': 'rie',
                },
                wrap: {
                    '1': 'Emballage',
                    '2': 'Cadeau',
                },
                item: {
                    '1': 'Objet',
                    '2': 'Cadeau',
                },
            },
        },
        card: {
            headerTitle: 'Carterie',
            metaDescription:
                'Un savoir faire unique, une fabrication française respectueuse de l’environnement, des collections aux couleurs éblouissantes et aux finitions parfaites',
            title: 'Carterie',
            description:
                'D’année en année notre catalogue s’étoffe, se sophistique, pour aller au-devant d’une clientèle plus raffinée, plus exigeante, plus que jamais éprise de l’air du temps.',
            description2:
                'Nos créations sont toujours plus chic, pour que l’acte d’écrire soit la preuve d’une belle et délicate attention et que la carte devienne un témoignage précieux à garder pour longtemps.',
        },
        wrap: {
            headerTitle: 'Emballage Cadeau',
            metaDescription:
                'Art&Kraft : Une vaste et irrésistible collection de papiers cadeaux, sacs cadeaux et accessoires',
            title: 'Emballage Cadeau',
            description:
                'Art&Kraft, ainsi nommée en référence au mouvement décoratif Arts and Crafts (art et savoir-faire) du début du siècle en Angleterre, signe la collection d’emballage cadeau créée et éditée en exclusivité par le Groupe EDITOR.',
            description2:
                'Forte d’un savoir- faire créatif inégalé, Art& Kraft interprète avec éclat les tendances à peine écloses, dans une profusion de styles, de couleurs et de finitions.',
        },
        item: {
            headerTitle: 'Objet Cadeau',
            metaDescription:
                'De jolis cadeaux qui font craquer petits et grands, personnalisés avec un prénom, un mot ou un symbole',
            title: 'Objet Cadeau',
            description:
                'EDITOR applique la même stratégie de créativité et de qualité sur ses lignes d’accessoires cadeaux décoratifs ou personnalisés, présentés en concepts et qui créent, avec la carterie et l’emballage, un univers complet autour de la communication d’émotion.',
        },
        group: {
            headerTitle: 'Le Groupe',
            metaDescription:
                'Leader européen en carterie, emballage cadeau, papeterie et objets cadeaux',
            title: 'Le Groupe',
            description:
                'Editor ? Des femmes et des hommes dont le métier, évènements, embrasements, éclats… est l’émotion. Métier que nous vivons comme un privilège, une chance ! Parce que l’émotion, évidemment c’est la vie. La carte choisie avec soin, le cadeau complice, le bel emballage sublimé de rubans.',
            description2:
                'Savoir manier les couleurs, les matières, les formes qui la produisent. Connaître les techniques de la surprise, les ressorts de l’affection qui la font naître à tout instant. Etre généreux, pétillants, positifs, surprenants, et naturellement fous d’air du temps, voilà notre métier. Au cœur de l’émotion !',
            history: {
                title: 'Notre Histoire',
                description1: 'Chez Editor, on aime les belles histoires.',
                description2:
                    'Ce n’est donc pas par hasard que l’on en vit une depuis 40 ans.',
                text1:
                    'Cette histoire a commencé avec un homme, Henry Condamine, fondateur et actuel Président du Groupe. Henry n’a que 21 ans lorsqu’il crée Eliophot, sa première société d’éditions à Aix-en-Provence. Il était déjà convaincu à l’époque que la carterie était un savoir-faire précieux et irremplaçable. Il n’a toujours pas changé d’avis !',
                text2:
                    'Et il n’est pas le seul à y croire… Depuis la création d’Eliophot en 1972, nous sommes 500 collaborateurs à l’avoir rejoint dans cette aventure pour former la grande famille d’Editor.',
            },
            value: {
                title: 'Nos Valeurs',
                description:
                    'Editor ? Des femmes et des hommes dont le métier, évènements, embrasements, éclats… est l’émotion. Métier que nous vivons comme un privilège, une chance ! Parce que l’émotion, évidemment c’est la vie. La carte choisie avec soin, le cadeau complice, le bel emballage sublimé de rubans. Savoir manier les couleurs, les matières, les formes qui la produisent. Connaître les techniques de la surprise, les ressorts de l’affection qui la font naître à tout instant. Etre généreux, pétillants, positifs, surprenants, et naturellement fous d’air du temps, voilà notre métier. Au cœur de l’émotion !',
                part1: {
                    title: 'Fabrication française',
                    description:
                        'Depuis décembre 2012, nous bénéficions du Label Origine France Garantie, délivré par Pro France suite à un audit de Bureau Veritas Certification, et qui permet de promouvoir «le produire en France».',
                },
                part2: {
                    title: 'Éco-responsable',
                    note: 'Nous commercialisons des produits certifiés FSC',
                    description:
                        'Conscients de l’impact de nos activités sur l’environnement et soucieux du devenir des forêts, nous nous sommes engagés dans une démarche visant à garantir que nos approvisionnements de papier proviennent des bois issus de forêts durablement protégées en se fondant sur les référentiels FSC<sup>®</sup> et PEFC™',
                },
                part3: {
                    title: "Partenariat avec l'UNICEF",
                    description:
                        'Depuis 2015, Editor est honoré de participer à la noble cause de l’UNICEF en mettant tout son savoir-faire et sa passion pour ainsi contribuer à aider les enfants.',
                },
            },
            knowHow: {
                title: 'Notre savoir-faire',
                description:
                    'Le Groupe EDITOR est leader en Europe Continentale sur les métiers de la carterie et de l’emballage cadeau.',
                part1: {
                    head: 'créativité',
                    title: 'Une créativité constamment renouvelée',
                    bloc1:
                        'Depuis plus de 35 ans, le Groupe a toujours investi massivement sur la création, la qualité des produits et le renouvellement des gammes.',
                    bloc2:
                        'Les produits sont créés en exclusivité par notre studio interne, le plus vaste en Europe avec 35 salariés, ou en collaboration avec des licences et des artistes reconnus.',
                    bloc3:
                        'Nous travaillons en partenariat avec plus de 60 agences et artistes internationaux.',
                },
                part2: {
                    head: 'production',
                    title: 'Un outil de production complet ',
                    bloc1:
                        'Développé en intégration verticale totale, de la conception à la commercialisation, EDITOR est fabricant dans les métiers du papier.',
                    bloc2:
                        'Son important site industriel est situé en Saône-et-Loire à Mâcon.',
                    bloc3:
                        'Sa chaîne de production (photogravure, impression, finitions et logistique) intègre les plus récentes innovations techniques.',
                },
                part3: {
                    head: 'commerce',
                    title: 'Une organisation commerciale puissante',
                    bloc1:
                        'Le réseau commercial exclusif assure un service complet auprès des magasins.',
                    bloc2:
                        'Les commerciaux conseillent les clients sur les produits les plus adaptés, mettent en place et gèrent les espaces dédiés.',
                    bloc3:
                        'Ambassadeurs de l’entreprise, ils sont de véritables partenaires des points de vente.',
                },
                part4: {
                    head: 'merchandising',
                    title: 'Les solutions merchandising innovantes',
                    bloc1:
                        'Des mobiliers innovants et esthétiques sont conçus pour mettre en valeur les différentes gammes de produits.',
                    bloc2:
                        'Modulables, ils s’adaptent à toutes les configurations d’espaces et de magasins.',
                },
            },
        },
        contact: {
            headerTitle: 'Contact',
            metaDescription:
                'Pour nous écrire, découvrez notre fiche de contact',
            title: 'Contact',
            form: {
                intro: 'Posez-nous toutes vos questions ici',
                object: {
                    label: 'Vous souhaitez ?',
                    value0: 'Auncun',
                    value1: 'Des informations sur nos produits ?',
                    value2: 'Échanger avec nos services commerciaux ?',
                    value3: 'Nous proposer vos idées ?',
                },
                email: {
                    label: 'Email',
                },
                message: {
                    label: 'Message',
                },
                submit: 'Envoyer',
                sent: 'Votre message a bien été envoyé',
            },
        },
        ml: ml,
        p404: {
            headerTitle: '404: Not found',
            title: '404 Not found',
            description: "Cette page n'existe pas... Désolé",
            link: "Retourner à l'accueil",
        },
    },
    products: {
        all: 'tous',
        copyrights: {
            base: '©Droits réservés',
        },
        collection: 'Collection {collection}',
        other: 'Autres images de la collection {collection} :',
        card: {
            children: {
                title: 'Enfant',
                bloc1:
                    'Des licences incontournables ainsi que d’adorables dessins naïfs ou fantasmagoriques',
                bloc2:
                    'EDITOR laisse libre cours à tout l’imaginaire enfantin. ',
            },
            general: {
                title: 'Généraliste',
                bloc1:
                    'EDITOR propose les indispensables séries qui constituent le socle de tout catalogue : collections Âge, cartes collectives, Pop- Up … ',
                bloc2:
                    'mais avec le talent et le « twist » qui les subliment ! ',
            },
            humour: {
                title: 'Humour',
                bloc1:
                    'D’irrésistibles collections d’humour, trempées à la plume tendre ou acide, de grands noms de l’illustration comme Alexander Holzach ou Voutch.',
                bloc2: 'Un segment incontournable du marché de la carterie.',
            },
            tendresse: {
                title: 'Tendresse',
                bloc1:
                    'De fabuleux succès interprétés avec charme et maestria dans des traités aquarellés baignés de lumière.',
                bloc2: 'Un savoir-faire reconnu de longue date.',
            },
            fashion: {
                title: 'Fashion',
                bloc1:
                    'L’effervescence et la justesse de collections qui saisissent l’air du temps dans des couleurs, des finitions et des textes à nuls autres pareils.',
                bloc2:
                    'Une collaboration constamment renouvelée avec des artistes du monde entier.',
            },
            // Fashion
            aurore: 'Graphismes modernes aux tonalités lumineuses et poudrées',
            'c-est-chic':
                'Des dessins sophistiqués et trendy aux tonalités profondes',
            click:
                'Clik, une nouvelle carte au traité photo très actuel rehaussé par un vernis sélectif et une dorure à chaud',
            'en-lettres-d-or':
                'Le grand savoir-faire typographique d’Editor, best-seller absolu du marché de la carterie',
            gourmandiz:
                'Des visuels acidulés et pétillants sur cartes petit format',
            'happy-word': 'Une collection fashion, graphique et multicolore',
            'serie-d-artiste':
                'Les œuvres magistrales de l’artiste Amy Sia interprétées en carterie',
            'very-chic':
                'Une collection toute en noir blanc et or, sobre, chic et élégante',
            'pop-cart': 'Des cartes éclatantes aux dorures étincelantes',
            // Generaliste
            collages:
                'Un feu d’artifice de couleurs et de collages pour ces cartes âges grand format',
            extravagance:
                'Un florilège de dessins variés sur grand format panoramique',
            'grand-comme-ca':
                'La carte collective, géante et maxi géante, pour tous les événements à fêter en groupe',
            'le-bel-age-en-rimes': 'Une collection âge toute en gaieté',
            'mise-en-scene': 'La carte cadeau qui se garde et s’expose',
            'poeme-du-jour':
                'De tendres poèmes sur des aquarelles aux couleurs éclatantes',
            'porte-billets':
                'Idéal pour les billets, chèques ou places de spectacles et adapté à une large cible',
            'post-script-homme':
                'La collection exclusivement destinée aux hommes',
            sparkle:
                'Sparkle, le nouveau carnet étincelant, gaufré, doré et imprimé sur papier glitter.',
            'stick-cartes':
                'Un large choix de stickers pour tous les âges et tous les styles',
            'un-petit-mot-vaut-mieux-quun-long-discours':
                'De l’esprit, de l’humour et de la légèreté !',
            // Humour
            'cha-cha-cha':
                'Des félins en folie dans des situations irrésistibles',
            'echec-et-mat':
                'Une collection craquante d’humour, d’amour et de pensées profondes',
            'farce-attrape':
                'Farce et attrape la nouvelle collection craquante d’humour, d’amour et de pensées profondes !',
            'la-x-et-la-maniere':
                'Toute une ménagerie d’animaux survoltés et très expressifs et des cases à cocher pour la personnalisation en plus',
            'geante-maxi-geante_showlapin-chachacha':
                'Géante Maxi Géante de Cha Cha Cha et Show Lapin',
            licorne: 'L’icône fashion en version humoristique !',
            'show-lapin':
                'Le best-seller humoristique, truculent et ébouriffant !',
            soundcart:
                'Des visuels et des textes percutants animés par des sons d’excellente qualité',
            voutch:
                'L’humour caustique de Voutch qui épingle tous les grands thèmes de la vie et de l’actualité',
            // Tendresse
            aerial: 'De magnifiques aquarelles d’artistes sur grand format',
            'comme-un-reve':
                'Aquarelles lumineuses et évanescentes empreintes de tendresse et de sérénité',
            'jardin-anglais':
                'Les dessins charmants et raffinés de l’artiste Harriet Mellor',
            paradisio:
                'Interprétation majestueuse de la nature luxuriante par l’artiste Gabby Malpas',
            'sugar-candy':
                'Des carnets tendres et pleins de fraîcheur aux découpes arrondies',
            // Unicef
            unicef:
                'Une collection tendre de visuels aquarellés pour écrire à ses proches et soutenir l’action de l’UNICEF',
            // Mural
            mural:
                'Des cartes pour tous les événements de la vie, aux styles, formats et finitions variés, adaptées à une large cible',
            // Enfant
            dreamworks:
                'Les stars des studios DreamWorks réunies dans une collection pétillante',
            'le-petit-monde-de-kali':
                'Le charmant univers de l’artiste Kali Stileman',
            // Claire Vision
            'de-profundis':
                'Des citations philosophiques et inspirantes sur un éventail de couleurs douces',
            'd-ici-et-d-ailleurs':
                'Des photos époustouflantes, mises en valeur par le format panoramique',
            'animaux-photos':
                'De la tendresse et de l’humour pour cette collection craquante',
            'cartes-textes':
                'Bien plus qu’une carte, un texte qui aide à grandir, à réfléchir, à tout moment, en tout lieu',
        },
        wrap: {
            animania: 'Une mise en scène féline drôle et craquante',
            aquacolor:
                'Les aquarelles lumineuses d’artistes renommées (Amy Sia, Nicola Gregory)',
            celeste:
                'Constellations d’étoiles et aurores boréales pour ce nouveau thème 2019',
            comics:
                'Jeux d’écritures ultra contemporaines et d’interjections dynamiques',
            gentleman: 'Gamme masculine aux motifs symboliques et géométriques',
            gourmandise: 'De gais dessins aux couleurs acidulées',
            'happy-kids': 'Dessins naïfs aux tons de sucre d’orge ',
            'les-unis':
                'Des unis et faux unis aux teintes subtiles et nuances délicates',
            love: 'Une profusion de cœurs dans de multiples traités picturaux',
            miniatures: 'Sobriété et minimalisme de petits motifs entrelacés',
            paradisio:
                'La nature luxuriante représentée dans cette nouvelle collaboration d’artistes(Gabby Malpas, Valérie Dumas)',
            pretty: 'Douce harmonie de petits motifs pastels ',
            spring: 'Brassées de fleurs et envolées de papillons multicolores',
            'ultra-chic':
                'Harmonie raffinée des noir, blanc et pastel rehaussés d’or',
        },
        item: {
            'mes-bougies-danniversaire':
                'Des bougies en forme de lettres colorées aux imprimés graphiques et acidulés',
            'mes-ballons-dannivresaire':
                'Des ballons colorés et imprimés d’un prénom dans une boîte acidulée originale',
            'mon-joli-stylo':
                'Des stylos chics et graphiques dans leur joli écrin',
            'mon-joli-petit-bijou':
                'Un collier personnalisé avec charms assortis dans sa jolie cartonnette dorée et colorée',
            'mon-porte-cles':
                'Un joli porte-clés en verre à offrir dans sa belle boîte cadeau',
        },
    },
    filter: {
        all: 'Tous',
        carterie: {
            enfant: 'Enfant',
            general: 'Généraliste',
            tendresse: 'Tendresse',
            fashion: 'Fashion',
            humour: 'Humour',
            mural: 'Mural',
            unicef: 'Unicef',
            'claire-vision': 'Claire Vision',
            finAnnee: "Fin d'année",
        },
    },
    actu: {
        title: 'En ce moment chez Editor',
        '01-click': {
            title:
                'Clik, une nouvelle carte au traité photo très actuel rehaussé par un vernis sélectif et une dorure à chaud',
            image: 'Clik2.jpg',
        },
        '02-bracelet': {
            title:
                'Nouveaux bracelets personnalisés avec médaillons gravés et perles colorées.',
            image: 'IMG_9660.jpg',
        },
        '03-sparkle': {
            title:
                'Sparkle, le nouveau carnet étincelant, gaufré, doré et imprimé sur papier glitter.',
            image: 'IMG_9939.jpg',
        },
        '04-paradisio': {
            title: 'Nouvelle gamme Emballage cadeau 2019 Paradisio',
            image: 'IMG_0040.jpg',
        },
        '05-rouge': {
            title:
                'Des pochettes souples, des boîtes à lunettes imprimées, de la dorure or, 4 univers graphiques !',
            image: 'MG_9709-rouge.jpg',
        },
        '06-farce-attrape': {
            title:
                'Farce et attrape la nouvelle collection craquante d’humour, d’amour et de pensées profondes !',
            image: 'Farce&attrape.jpg',
        },
        '07-frankfort': {
            title:
                'Edition 2019 du Stand Editor au salon international Paperworld à Francfort',
            image: 'GrEditor-Paperw19-5249.jpg',
        },
    },
    errors: {
        emptyField: 'Veillez remplir le champ {field}',
        invalidEmail: "Le format d'email est invalide",
        global: "Une erreur s'est produite",
    },
};
