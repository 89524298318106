import ml from './pages/ml';

export default {
    general: {
        lang: 'language',
        editor: 'Groupe Editor',
    },
    nav: {
        home: 'Home page',
        card: 'Cards',
        wrap: 'Gift wrap',
        item: 'Giftware',
        group: 'The Group',
        carrer: 'Job offers',
        contact: 'Contact',
        ml: 'Terms of use',
    },
    pages: {
        meta: {
            title:
                'Groupe Editor | European market leader for cards, gift wrap, stationery and gifts',
            titleAlt:
                'Groupe Editor | European market leader for cards, gift wrap, stationery and gifts',
            description: `The market leader in continental Europe for cards and gift wrap`,
            author: `@JimmyBeldone`,
        },
        home: {
            headerTitle: 'Home page',
            metaDescription:
                'Designer, publisher and distributor to the trade sector, Editor is European market leader for cards, gift wrap, stationery and giftware',
            title: 'Homepage',
            catch: "De l'art, de l'esprit, de l'unique",
            description:
                ' The market leader in continental Europe for cards and gift wrap',
            landing: {
                title: 'See all our products',
                card: {
                    '1': 'Cards',
                    '2': 'Cards',
                },
                wrap: {
                    '1': 'wrap',
                    '2': 'Gifts',
                },
                item: {
                    '1': 'items',
                    '2': 'Gifts',
                },
            },
        },
        card: {
            headerTitle: 'Cards',
            metaDescription:
                'Applying its unique know how to offer an array of perfectly finished, enviromentally friendly, made in France ranges bursting with colour',
            title: 'Cards',
            description:
                ' Year on year we develop our catalogue refining it to cater for an ever more discerning, more demanding clientele keen to discover the latest trends.',
            description2:
                ' We strive to create more and more stylish designs, enabling our customers to enjoy sending a beautiful and heartfelt token, giving the recipient a precious token to be treasured for years to come.',
        },
        wrap: {
            headerTitle: 'Gift wrap',
            metaDescription:
                'Art&Kraft: an extensive, stunning range of gift wrap, gift bags and ribbons',
            title: 'Gift wrap',
            description:
                ' Art&Kraft”, our collection of gift wrap designed and created exclusively for Groupe EDITOR, derives its name from the decorative  Arts and Crafts movement which developed in England at the start of the  Twentieth Century.',
            description2:
                ' Our strength lies in our unequalled creative know how. “Art& Kraft” conveys brilliantly the very latest trends with a broad range of styles, colours and finishes.',
        },
        item: {
            headerTitle: 'Giftware',
            metaDescription:
                'Irresistible gift items for big and small, personnalized with a name, a word or a symbol',
            title: 'Giftware',
            description:
                ' EDITOR applies the same creative skills and desire to attain the highest quality to its range of decorative and personalized gift accessories.  Perfectly complimenting our cards and gift wrap they offer a wide array of products to express our every emotion',
        },
        group: {
            headerTitle: 'The Group',
            metaDescription:
                'European market leader for cards, gift wrap, stationery and gifts',
            title: 'The Group',
            description:
                ' Who are Editor ?  our employees, both men and women, with a passion that drives and inspires them, motivated by heartfelt emotion. Our job is a privilege (and our good fortune!) because being able to express our every emotion is what life is all about. A carefully chosen card, a thoughtful gift, immaculately wrapped and tied with a bow, can articulate our every desire.',
            description2:
                ' Deft handling of colour combinations, textures and shapes brings emotion to life. Expert knowledge of how to instantly surprise or touch the heart strings. To be generous, to sparkle, to be positive, spontaneous, and of course, innovative, identifying the latest trends, that is our job.  We’re at the heart of expression! ',
            history: {
                title: 'About us',
                description1: 'At Editor, we like beautiful stories.',
                description2:
                    ' It’s no accident that we’ve been living one for 40 years.',
                text1:
                    ' This story started with a man, Henry Condamine, founder and current Chairman of the Group. Henry was just 21 years old when he founded Eliophot, his first publishing company in Aix-en-Provence. He  already firmly believed then  how precious and irreplaceable  greeting cards are. That conviction remains!',
                text2:
                    ' And he isn’t the only one… Since Eliophot was created in1972, 500 employees have joined him to create the great Editor family. ',
            },
            value: {
                title: 'Our Values',
                description:
                    ' Who are Editor ?  our employees, both men and women, with a passion that drives and inspires them, motivated by heartfelt emotion. Our job is a privilege (and our good fortune!) because being able to express our every emotion is what life is all about. A carefully chosen card, a thoughtful gift, immaculately wrapped and tied with a bow, can articulate our every desire.Editor  Deft handling of colour combinations, textures and shapes brings emotion to life. Expert knowledge of how to instantly surprise or touch the heart strings. To be generous, to sparkle, to be positive, spontaneous, and of course, innovative, identifying the latest trends, that is our job.  We’re at the heart of expression!',
                part1: {
                    title: 'Made in France',
                    description:
                        ' Since December 2012, we have been proud to hold the “Guaranteed Made in France label”. Awarded and certified by Pro France after an audit carried out by the Bureau Veritas this certification was devised to promote goods manufactured in France.',
                },
                part2: {
                    title: ' Environmentally friendly ',
                    note: ' We sell FSC certified products',
                    description:
                        ' Conscious of the impact of our activities on the environment and concerned for the future of our forests, we are all committed to being able to guarantee that our paper supplies come from wood sourced from sustainably managed forests conforming with FSC<sup>®</sup> et PEFC™',
                },
                part3: {
                    title: ' Partnership with UNICEF',
                    description:
                        ' Since 2015, Editor has been honoured to contribute to UNICEF’s noble aims,  putting all its know-how and passion into practice to help children.',
                },
            },
            knowHow: {
                title: ' Our know-how',
                description:
                    ' Groupe EDITOR, market leader in Continental Europe for cards and gift wrap.',
                part1: {
                    head: ' Creativity',
                    title: ' Creativity constantly refined',
                    bloc1:
                        ' For over 35 years, the Group has always heavily invested in creativity and  quality to constantly renew its product.',
                    bloc2:
                        ' our products are designed exclusively at own studio, the largest in Europe with 35 employees, or in association with licensed properties and accomplished artists.',
                    bloc3:
                        ' We work closely with over 60 design studios and international artists.',
                },
                part2: {
                    head: ' Printing',
                    title: ' An extensive production plant',
                    bloc1:
                        ' Developed with full vertical integration, from design and conception through to sales and distribution, EDITOR is a manufacturer in the paper.',
                    bloc2:
                        ' Its significant industrial plant is located at Mâcon in the Saône-et-Loire region.',
                    bloc3:
                        ' Its production line (photo-engraving, printing, finishing and logistics) integrate the most recent innovative techniques.',
                },
                part3: {
                    head: 'Sales',
                    title: ' A powerful sales organization',
                    bloc1:
                        ' An exclusive sales force provides a comprehensive service to sales outlets.',
                    bloc2:
                        ' The sales force advise clients on the best choice of products and display and their presentation in uniquely dedicated spaces.',
                    bloc3:
                        ' Ambassadors for the company, they prove to be genuine partners for the sales outlets.',
                },
                part4: {
                    head: 'merchandising',
                    title: ' Innovative merchandising solutions',
                    bloc1:
                        ' Innovative and aesthetic sales fixtures are designed to display the different product  ranges to their full potential.',
                    bloc2:
                        ' Their modular design means that they can adapt to all configurations and outlets.',
                },
            },
        },
        contact: {
            headerTitle: 'Contact',
            metaDescription:
                'Any questions or queries, we’d love to hear from you, select a contact form',
            title: 'Contact',
            form: {
                intro: 'Have you a question ?',
                object: {
                    label: 'How can we help?',
                    value0: 'None',
                    value1: 'Product information?',
                    value2: 'Contact our commercial services?',
                    value3: 'Submit ideas?',
                },
                email: {
                    label: 'Email',
                },
                message: {
                    label: 'Message',
                },
                submit: 'Send',
                sent: 'Your message has been sent successfully',
            },
        },
        ml: ml,
        p404: {
            headerTitle: '404: Not found',
            title: '404: Not found',
            description: 'This page does not exist... Sorry',
            link: 'Return to home page',
        },
    },
    products: {
        all: 'all',
        copyrights: {
            base: '© All rights reserved',
            dreamworks: '©2019 Universal Studios. All Rights Reserved',
        },
        collection: '{collection} collection',
        other: 'Other designs from the {collection} collection:',
        card: {
            children: {
                title: 'Children',
                bloc1:
                    ' Indispensable licensed properties as well as adorable naive or fantasmagoric illustrations',
                bloc2:
                    ' EDITOR gives free rein to  a child’s imaginary world. ',
            },
            general: {
                title: 'General',
                bloc1:
                    ' EDITOR offers indispensable lines that make up the basis of all catalogues : Age ranges, collective cards, Pop- Up… ',
                bloc2:
                    ' but with that little bit extra  that enhances our products to their full potential ! ',
            },
            humour: {
                title: 'Humor',
                bloc1:
                    ' Irresistibly humorous collections, sweet or sharp, captured in a stroke of a pen, by great illustrators and cartoonists such Alexander Holzach or Voutch.',
                bloc2: ' A vital segment in the card market.',
            },
            tendresse: {
                title: ' Love and friendship',
                bloc1:
                    ' A fabulous success interpreted with charm and great skill in luminous watercolour.',
                bloc2: ' A renowned and long established expertise.',
            },
            fashion: {
                title: 'Fashion',
                bloc1:
                    ' effervescent and  precise collections which perfectly capture the very latest trends through their colours, their textures and unmatchable texts.',
                bloc2:
                    ' A constantly renewed collaboration with artists throughout the world.',
            },
            // Fashion
            aurore: ' Modern graphic designs  in soft, luminous tones',
            'c-est-chic': ' Refined and up-beat designs in striking colours',
            click:
                'Clik, a new snapshot style card - the latest thing!  enhanced with a spot varnish and hot stamp finish.',
            'en-lettres-d-or':
                ' An example of Editor’s great typographical expertise, an absolute best-seller in the card market',
            gourmandiz:
                ' Bright, fresh colours combined with small sized cards',
            'happy-word':
                ' A fashionionable collection, graphic and multicoloured',
            'serie-d-artiste':
                ' The magnificent works of the artist Amy Sia brought to cards',
            'very-chic':
                ' A black, white and gold collection, sober, chic and elegant',
            'pop-cart': ' Dazzling cards with sparkling hot stamp finish',
            // Generaliste
            collages:
                ' A firework of colour and shapes for these large format age cards',
            extravagance:
                ' An array of varied designs in a large panoramic format',
            'grand-comme-ca':
                ' Collective cards, maxi and jumbo formats, to celebrate all occasions as a group',
            'le-bel-age-en-rimes': ' A bright and cheerful age collection',
            'mise-en-scene': ' A gift card, a keepsake to treasure',
            'poeme-du-jour':
                ' Lyrical poems presented with brightly coloured watercolours',
            'porte-billets':
                ' Ideal for gift tokens, cheques and concert tickets for a wide spectrum of consumers',
            'post-script-homme':
                ' A masculine collection exclusively aimed at the male market',
            sparkle:
                'Sparkle, the dazzling new card range, printed on a glitter board with emboss and foil finish.',
            'stick-cartes':
                ' A masculine collection exclusively aimed at the male market',
            'un-petit-mot-vaut-mieux-quun-long-discours':
                ' A light hearted colleciton, full of spirit and humor !',
            // Humour
            'cha-cha-cha': ' Crazy cats in irresistible situations',
            'echec-et-mat':
                ' An appealing collection full of humor, love and deep emotion',
            'farce-attrape':
                '« Farce et attrape » the new collection full of fun, love and profound thoughts',
            'la-x-et-la-maniere':
                ' A whole spectrum of frantic very expressive animals that tick  every box to suit that particular recipient',
            'geante-maxi-geante_showlapin-chachacha':
                ' Maxi and Jumbo Cards with Cha Cha Cha and Show Lapin designs',
            licorne: ' A humourous version of the fashion icon!',
            'show-lapin':
                ' The humourous best-seller, down to earth and  to the point!',
            soundcart:
                ' Striking imagery and texts animated by top quality sound modules',
            voutch:
                ' Voutch’s caustic humor commenting on all the major themes in modern day life',
            // Tendresse
            aerial: ' Magnificent watercolours in a large format',
            'comme-un-reve':
                ' Luminous watercolours, a glowing depiction  of love and serenity',
            'jardin-anglais':
                ' Charming and elegant designs by the artist Harriet Mellor',
            paradisio:
                ' A majestic interpretation of luxuriant nature by the artist Gabby Malpas',
            'sugar-candy':
                ' Touching notes, innovative, with a rounded die-cut finish',
            // Unicef
            unicef:
                ' A collection of watercolours to write to loved ones and support UNICEF',
            // Mural
            mural:
                ' Cards for every event in life, with diverse styles, formats and finishes, to cater to a wide range of consumers',
            // Enfant
            dreamworks:
                ' Stars from DreamWorks reunited in a collection bursting with colour',
            'le-petit-monde-de-kali':
                ' The charming world of the artist Kali Stileman',
            // Claire Vision
            'de-profundis':
                ' Philosophical and inspirational quotations presented in a  wide array of soft colours',
            'd-ici-et-d-ailleurs':
                ' Breathtaking photos enhanced by their panoramic format',
            'animaux-photos':
                ' Love and humor combined in this irresistible collection',
            'cartes-textes':
                ' So much more than a card, a thought provoking text that helps one grow and reflect at that critical moment',
        },
        wrap: {
            animania: ' A funny and appealing cat, given centre stage',
            aquacolor:
                ' Luminous watercolours by reknowned artists (Amy Sia, Nicola Gregory)',
            celeste:
                ' Constellations of stars and the Northern lights are a new theme for 2019',
            comics:
                ' Ultra contemporary play on words and dynamic interjections',
            gentleman: ' A masculine range of symbols and geometric patterns',
            gourmandise: ' Cheerful brightly coloured designs',
            'happy-kids': ' Naive designs in sugar candy shades',
            'les-unis':
                ' Plain and semi plain in subtle shades and delicate tones',
            love: ' A profusion of hearts in multiple styles',
            miniatures:
                ' Sober and minimalist with delicate interlaced patterns',
            paradisio:
                ' Luxuriant nature portrayed in this new project by artists (Gabby Malpas, Valérie Dumas)',
            pretty: 'A soft harmony of delicate pastel patterns',
            spring: 'Bursting with flowers and multicoloured butterflies',
            'ultra-chic':
                'A harmonious and refined combination of black, white and pastel shades enhanced with gold',
        },
        item: {
            'my birthday candles':
                'Brightly coloured candles in the shape of coloured letters with printed graphic designs',
            'my birthday ballons':
                'Coloured balloons with names printed on them in a brightly coloured innovative box',
            'my pretty pen':
                'Elegant pens with graphic designs in their attractive case',
            'my pretty piece of jewellery':
                'a personnalized necklace with a matching charm in its pretty gold and coloured packaging',
            'my key-ring': 'My pretty glass key-ring in its beautiful gift box',
        },
    },
    filter: {
        all: 'All',
        carterie: {
            enfant: 'Children',
            general: 'General',
            tendresse: 'Love and Friendship',
            fashion: 'Fashion',
            humour: 'Humor',
            mural: 'For wall fixtures',
            unicef: 'Unicef',
            'claire-vision': 'Claire Vision',
            finAnnee: 'Seasonal',
        },
    },
    actu: {
        title: 'What’s happening at Editor',
        '01-click': {
            title:
                'Clik, a new snapshot style card - the latest thing!  enhanced with a spot varnish and hot stamp finish.',
            image: 'Clik2.jpg',
        },
        '02-bracelet': {
            title:
                'New personalised bracelets with engraved disc and coloured beads.',
            image: 'IMG_9660.jpg',
        },
        '03-sparkle': {
            title:
                'Sparkle, the dazzling new card range, printed on a glitter board with emboss and foil finish.',
            image: 'IMG_9939.jpg',
        },
        '04-paradisio': {
            title: 'Paradisio, New gift wrap range 2019',
            image: 'IMG_0040.jpg',
        },
        '05-rouge': {
            title:
                'Fabric zip bags and printed glasses cases, with gold finish, available in 4 different graphic styles!',
            image: 'MG_9709-rouge.jpg',
        },
        '06-farce-attrape': {
            title:
                '« Farce et attrape » the new collection full of fun, love and profound thoughts',
            image: 'Farce&attrape.jpg',
        },
        '07-frankfort': {
            title:
                'Editor’s stand at the international trade fair Paperworld 2019 in Frankfurt',
            image: 'GrEditor-Paperw19-5249.jpg',
        },
    },
    errors: {
        emptyField: 'Complete the field {field}',
        invalidEmail: 'Email format invalid',
    },
};
