import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import ScrollUpButton from 'react-scroll-up-button';
import Fab from '@material-ui/core/Fab';
import ArrowUpIcon from '@material-ui/icons/ArrowUpward';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    fab,
    faFacebookF,
    faYoutube,
    faInstagram,
    faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';

import I18nProvider from '../../providers/I18nProvider';
import { getLangs } from '../../utils/lang';
import { WebpProvider } from '../components/WebpDetect';

import Header from './Header';
// import Footer from "./Footer";

library.add(fab, faFacebookF, faYoutube, faInstagram, faLinkedinIn);

const MainLayout = ({ locale, children, is404, originalPath, slugId }) => {
    if (typeof window !== `undefined`) {
        sessionStorage.setItem('lang', locale);
    }

    const langsMenu = getLangs(locale, originalPath, is404);
    const { site } = useStaticQuery(
        graphql`
            query LayoutQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `,
    );

    return (
        <I18nProvider locale={locale}>
            <div id='app'>
                <WebpProvider>
                    <>
                        <Header
                            siteTitle={site.siteMetadata.title}
                            langs={langsMenu}
                        />
                        <div>
                            <main>{children}</main>
                            <ScrollUpButton
                                AnimationDuration={700}
                                EasingType='easeInQuad'
                                ContainerClassName='scrolltop-container'
                                TransitionClassName='AnyClassForTransition'
                                style={{ zIndex: 9 }}
                            >
                                <Fab
                                    // color="primary"
                                    color='primary'
                                    aria-label='srcoll top'
                                    // onClick={toggleDrawer(true)}
                                    // edge="start"
                                    size='medium'
                                    // className={cn("menuButton", { hide: isOpen })}
                                >
                                    <ArrowUpIcon />
                                </Fab>
                            </ScrollUpButton>
                            {/* <Footer /> */}
                        </div>
                    </>
                </WebpProvider>
            </div>
        </I18nProvider>
    );
};

MainLayout.defaultProps = {
    is404: false,
    slugId: null,
};

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
    locale: PropTypes.string.isRequired,
    originalPath: PropTypes.string.isRequired,
    slugId: PropTypes.string,
    is404: PropTypes.bool,
};

export default MainLayout;
