import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// import { checkWebp } from "../../../utils/img";
import "./ResponsiveBgImg.styl";
import { useWebp } from "../WebpDetect";

const ResponsiveBgImg = ({
    src,
    srcSet,
    srcWebp,
    srcSetWebp,
    alt,
    sizes,
    base64,
    tracedSVG,
    className,
    style,
    children,
    removeBgOnLoaded,
    parallax,
    aspectRatio
}) => {
    const { initiated, hasWebp } = useWebp();

    const img = useRef(null);
    const loadingBg = tracedSVG !== null ? tracedSVG : base64;
    const [bgSrc, setBgSrc] = useState(null);
    const isMobile = useMediaQuery("(max-width: 767px)");

    const renderImage = () => {
        const newSrc = hasWebp ? srcWebp : src;
        const newSrcSet = hasWebp ? srcSetWebp : srcSet;

        const isIE = () => {
            const ua = navigator.userAgent;
            return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
        };

        let newsSizes = sizes;
        if (parallax) {
            // const test = `(min-aspect-ratio: ${aspectRatio}) calc(100vh * ${aspectRatio}), 100w`;
            const widthMobile = Math.round(100 * aspectRatio);
            const test = `(min-height: 100vh) ${widthMobile}vh, 100w`;
            newsSizes = isMobile ? test : sizes;
        }

        return (
            <img
                ref={img}
                src={newSrc}
                alt={alt}
                srcSet={newSrcSet}
                sizes={newsSizes}
                // sizes={"(min-aspect-ratio: 10/3) 100vh, calc(100 * (10 / 3))"}
                style={{ display: "none" }}
                onLoad={() => {
                    if (isIE()) {
                        setBgSrc(newSrc);
                    } else {
                        setBgSrc(img.current.currentSrc);
                    }
                }}
            />
        );
    };

    return (
        <div
            style={style}
            className={cn("responsive-img-container", className)}
        >
            <div
                className={cn("responsive-img-loading", {
                    hide: removeBgOnLoaded && bgSrc !== null
                })}
                style={!bgSrc ? { backgroundImage: `url("${loadingBg}")` } : {}}
            />
            <div
                className={cn("responsive-img", {
                    loaded: bgSrc !== null
                })}
                style={{ backgroundImage: `url(${bgSrc})` }}
                title={alt}
            />
            {initiated ? renderImage() : null}
            {children}
        </div>
    );
};

ResponsiveBgImg.defaultProps = {
    className: "",
    base64: null,
    tracedSVG: null,
    style: {},
    removeBgOnLoaded: true,
    parallax: false
};

ResponsiveBgImg.propTypes = {
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string.isRequired,
    srcWebp: PropTypes.string.isRequired,
    srcSetWebp: PropTypes.string.isRequired,
    sizes: PropTypes.string.isRequired,
    base64: PropTypes.string,
    tracedSVG: PropTypes.string,
    className: PropTypes.string,
    alt: PropTypes.string.isRequired,
    style: PropTypes.object,
    children: PropTypes.node,
    removeBgOnLoaded: PropTypes.bool.isRequired,
    parallax: PropTypes.bool.isRequired
};

export default ResponsiveBgImg;
