import ml from './pages/ml';

export default {
    general: {
        lang: 'Taal',
        editor: 'Groep Editor',
    },
    nav: {
        home: 'Home',
        card: 'Wenskaarten',
        wrap: 'Verpakking',
        item: 'Geschenkartikel',
        group: 'De Groep',
        carrer: 'Jobs',
        contact: 'Contact',
        ml: 'Wettelijke vermeldingen',
    },
    pages: {
        meta: {
            title:
                'Groep Editor | Europese leader in wenskaarten, verpakking, papeterie en geschenkartikelen',
            titleAlt:
                'Groep Editor | Europese leader in wenskaarten, verpakking, papeterie en geschenkartikelen',
            description: `Europese leader in wenskaarten, verpakking, papeterie en geschenkartikelen`,
            author: `@JimmyBeldone`,
        },
        home: {
            headerTitle: 'Home',
            metaDescription:
                'Createur, drukker en distributeur bij professionelen. Editor is Europese leader in wenskaarten, verpakking en geschenkartikelen',
            title: 'Home',
            catch: 'Kunst, creativiteit, uniek',
            description:
                'Leader in continentaal Europa op het beroep van wenskaarten en verpakking',
            landing: {
                title: 'Ontdek onze producten',
                card: {
                    '1': 'wenskaarten',
                    '2': 'rie',
                },
                wrap: {
                    '1': 'Verpakking',
                    '2': 'Geschenk',
                },
                item: {
                    '1': 'Artikel',
                    '2': 'Geschenk',
                },
            },
        },
        card: {
            headerTitle: 'Wenskaarten',
            metaDescription:
                'Een unieke kennis, een Franse productie met respect voor het milieu, prachtig gekleurde collecties met perfecte afwerkingen',
            title: 'Wenskaarten ',
            description:
                'Door de jaren heen wordt onze cataloog steeds groter en beter om een steeds meer geraffineerde en veeleisend cliënteel te kunnen voldoen.',
            description2:
                'Onze creaties worden telkens eleganter zodat de kunst van het schrijven een mooi bewijs en een tedere attentie wordt en dat wenskaarten een stijlvolle herinnering worden om lang bij te houden.',
        },
        wrap: {
            headerTitle: 'Verpakking',
            metaDescription:
                'Art & Kraft: een onweerstaanbare et grote collectie van inpakpapier, geschenkzakjes en accessoires',
            title: 'Verpakking',
            description:
                'Art&Kraft werd genoemd naar een decoratieve stijl (art et savoir-faire) van in het begin van de eeuw in Engeland. De ondertekende verpakking van Groupe EDITOR wordt in exclusiviteit gecreëerd.',
            description2:
                'Sterk in onze kennis, een unieke creativiteit verhoogd met tendensen en in een eigen kleurstijl en afwerkingen.',
        },
        item: {
            headerTitle: 'Geschenkartikel',
            metaDescription:
                'Mooie geschenken voor zowel jong en oud, gepersonaliseerd met een voornaam, een woord of een symbool',
            title: 'Geschenkartikel',
            description:
                'EDITOR heeft dezelfde strategie van creativiteit en kwaliteit op zowel de geschenkartikelen als op de wenskaarten.',
        },
        group: {
            headerTitle: 'De Groep',
            metaDescription:
                'Europese leader in wenskaarten, verpakking, papeterie en geschenkartikelen',
            title: 'De Groep',
            description:
                'Editor ? Dit zijn vrouwen en mannen waarvan het beroep een handvol van evenementen, straling en emotie is. Een beroep dat men met volle hart beleven, meeleven. Natuurlijk omdat emotie deel uitmaakt van ons leven. De wenskaart perfect uitkiezen, een perfect cadeau en de mooiste verpakking.',
            description2:
                'Kleuren, stijlen en vormen kunnen hanteren. Het verrassingseffect doet dat alles ineens gebeurd. Vrijgevig zijn, intens, positief en natuurlijk tendens zijn: dit is onze job. In het hart van de emotie!',
            history: {
                title: 'Ons verhaal',
                description1: 'Bij Editor houden we van mooie verhalen.',
                description2:
                    'Het is dus geen toeval dat we dit mooie verhaal al meer dan 40 jaar leven.',
                text1:
                    'Dit verhaal begon met één man, Henry Condamine. Hij is de stichter en actuele president van de groep. Henry was slechts 21 jaar wanneer hij Eliophot creërde. ',
                text2:
                    'En hij is niet de enige die erin geloofde… Sinds de stichting van Eliophot in 1972, zijn we 500 werknemers die mee in het avontuur sprongen en zo mee in de grote familie van Editor zijn gesprongen. ',
            },
            value: {
                title: 'Onze waarden',
                description:
                    'Editor ? Dit zijn vrouwen en mannen waarvan het beroep een handvol van evenementen, straling en emotie is. Een beroep die we als een kans beschouwen! Omdat emotie natuurlijk het leven is. De kaart die met zorg werd uitgekozen, de mooie verpakking. De technieken van verrassing kennen en liefde uitstralen. Gulzig, lief, positief, verrassend en natuurlijk tendens zijn, dit is ons beroep. In het hart van de emotie!',
                part1: {
                    title: 'Franse productie',
                    description:
                        'Sinds december 2012 hebben we het label « Origine France Garantie » uitgegeven aan het einde van een audit door het bureau Veritas Certification, welke garandeerde dat het product in Frankrijk wordt geproduceerd.',
                },
                part2: {
                    title: 'Eco-verantwoordelijk',
                    note: 'We commercialisern gecertifieerde FSC producten.',
                    description:
                        'We zijn bewust van de impact van onze activiteiten op het milieu en we zijn bekommerd voor de toekomst van de bossen. We hebben ons verbonden om onze bevoorrading van papier uit duurzame beheerde bossen komt en dit gebaseerd op FSC<sup>®</sup> et PEFC™',
                },
                part3: {
                    title: 'Partenariaat met UNICEF',
                    description:
                        'Sinds 2015 is Editor vereerd om aan het mooie doel van UNICEF deel te nemen met onze kennis en passie en dit om kinderen te helpen.',
                },
            },
            knowHow: {
                title: 'Onze kennis',
                description:
                    'De groep EDITOR is leader in Continentaal Europa op de beroepen van wenskaarten en verpakking.',
                part1: {
                    head: 'creativiteit',
                    title: 'Een steeds hernieuwde creativiteit.',
                    bloc1:
                        'Het is meer dan 35 jaar dat de Groep investeert in créatie, kwaliteit en vernieuwing van zijn assortiment.',
                    bloc2:
                        'De producten worden in exclusiviteit in onze studio gecreëerd. Eén van de grootste in Europa met 35 werknemers of in samenwerking met artiesten of licenties.',
                    bloc3:
                        'We werken hand in hand met ongeveer 60 agentschappen en herkende artiesten.',
                },
                part2: {
                    head: 'productie',
                    title: 'Een volledig productieproces ',
                    bloc1:
                        'Volledig geïntegreerd vanaf de conceptie tot de verkoop. Editor is fabrikant in papier.',
                    bloc2:
                        'Zijn belangrijke industriële site is gebaseerd in de Saône-et-Loire in Mâcon.',
                    bloc3:
                        'Zijn productieketen (photogravure, druk, afwerking en logistiek) beheerd de meest innoverende technieken.',
                },
                part3: {
                    head: 'Verkoop',
                    title: 'Een krachtig verkoopsteam',
                    bloc1:
                        'Ons verkoopsteam verzorgt een exclusieve service aan de winkels.',
                    bloc2:
                        'Onze verkopers bezorgen de beste diensten en uitleg aan onze klanten over de beste producten en beheren optimaal de commerciële plaats. ',
                    bloc3:
                        'Als ambassadeurs van het bedrijf zijn ze echte partners voor de verkooppunten.',
                },
                part4: {
                    head: 'merchandising',
                    title: 'Innoverende merchandising oplossingen',
                    bloc1:
                        'Innoverend en esthetisch meubilair om de verschillende producten hun waarde te geven.',
                    bloc2:
                        'Moduleerbaar, ze kunnen elke configuratie van de winkel aan.',
                },
            },
        },
        contact: {
            headerTitle: 'Contact',
            metaDescription:
                'Ontdek onze contactfiche indien u wenst ons te contacteren',
            title: 'Contact',
            form: {
                intro: 'Stel uw vragen hier',
                object: {
                    label: 'U bekomt ?',
                    value0: 'Niets',
                    value1: 'Informatie over onze producten ?',
                    value2: 'Contact met onze commerciële dienst ?',
                    value3: 'Wenst u ideeën voor te leggen?',
                },
                email: {
                    label: 'Email',
                },
                message: {
                    label: 'Bericht',
                },
                submit: 'Versturen',
            },
        },
        ml: ml,
        p404: {
            headerTitle: '404: Not found',
            title: '404: Not found',
            description: 'Deze pagina bestaat niet... Sorry',
            link: 'Terug naar de home pagina',
        },
    },
    products: {
        all: 'alles',
        copyrights: {
            base: '©All Rights reserved',
            dreamworks: '©2019 Universal Studios. All Rights Reserved',
        },
        collection: 'Collectie {collection}',
        other: 'Andere beelden van de collectie {collection} :',
        card: {
            children: {
                title: 'Kinderen',
                bloc1: 'Belangrijke licenties met naïeve tekeningen ',
                bloc2: 'EDITOR laat verbeelding toe. ',
            },
            general: {
                title: 'Generaal',
                bloc1:
                    'EDITOR stelt onvermijdelijke collecties voor. Zoals Uw Top Jaar, groepskaarten, Pop- Up … ',
                bloc2: 'Maar altijd met talent en stijl ',
            },
            humour: {
                title: 'Humor',
                bloc1:
                    'Humoristische collecties verzorgt door grote namen zoals Alexander Holzach of Voutch.',
                bloc2: 'Een onvermijdelijk segment in de kaartenwereld.',
            },
            tendresse: {
                title: 'Tederheid',
                bloc1:
                    'Het fabuleus succes van charrme en tederheid in aquareltinten.',
                bloc2: 'Een erkende kennis',
            },
            fashion: {
                title: 'Fashion',
                bloc1:
                    'De peps en juistheid van de collecties die tendens liggen in de kleuren, afwerkingen en teksten.',
                bloc2:
                    'Een steeds vernieuwde samenwerking met artiesten van over heel de wereld',
            },
            // Fashion
            aurore: 'Moderne tekeningen in lichte kleuren',
            'c-est-chic': 'Trendy tekeningen in donkere tinten',
            click:
                'Click, een nieuwe kaart met een zeer actuele fotobehandeling versterkt met goudfolie en vernis.',
            'en-lettres-d-or':
                'Een grote kennis van typographique door Editor. Een absolute best-seller in de wereld van de wenskaarten.',
            gourmandiz: 'Gekleurde afbeeldingen op een klein formaat.',
            'happy-word': 'Een fashion collectie vol kleur.',
            'serie-d-artiste': 'Mooie afbeeldingen van de artiest Amy Sia',
            'very-chic':
                'Een collectie helemaal in het wit, zwart en goud, sober elegant en chic.',
            'pop-cart': 'Kaarten vol met goud',
            // Generaliste
            collages:
                'Een vuurwerk van kleuren voor deze kaarten voor leeftijden in groot formaat. ',
            extravagance: 'Gevarieerde tekeningen op groot formaat.',
            'grand-comme-ca':
                'De collectieve kaart, groot en maxi, voor alle evenementen die in groep te vieren zijn',
            'le-bel-age-en-rimes': 'Een leuke collectie',
            'mise-en-scene': 'Een kaart zoals een cadeau',
            'poeme-du-jour': 'Tedere gedichten in lichte tinten',
            'porte-billets': 'Ideaal voor biljetten of concertplaatsen. ',
            'post-script-homme': 'Een exclusieve collectie voor mannen.',
            sparkle:
                'Sparkle, een nieuwe fonkelende wenskaart met preeg en goudfolie bedrukt op glitter papier.',
            'stick-cartes': 'En leuke sticker collectie',
            'un-petit-mot-vaut-mieux-quun-long-discours':
                'Humor en tederheid !',
            // Humour
            'cha-cha-cha': 'Dolle katten in een rare situatie',
            'echec-et-mat':
                'Een humoristische collectie vol liefde en toffe gedachten.',
            'farce-attrape':
                '“Farce et Attrape” de nieuwe collectie vol humor, liefde en diepe gedachten!',
            'la-x-et-la-maniere':
                'Dieren in al hun staten om een leuk bericht over te brengen. ',
            'geante-maxi-geante_showlapin-chachacha':
                'Groot Maxi Groot van Cha Cha Cha en Nic',
            licorne: 'De fashion éénhoorn !',
            'show-lapin': 'Een best-seller, humoristich en fun !',
            soundcart:
                'Geanimeerde teksten en afbeeldingen met een leuk geluid ',
            voutch:
                'Specifieke humor van Voutch die het leven naar voren brengt.',
            // Tendresse
            aerial: 'Mooie aquarellen op groot formaat',
            'comme-un-reve':
                'Tederheid en menselijkheid in mooie kleurijke tinten. ',
            'jardin-anglais': 'Mooie tekeningen door de artiest Harriet Mellor',
            paradisio:
                'Mooie interpretatie van de natuur door de artiest Gabby Malpas',
            'sugar-candy': 'Kaarten vol tederheid en frisheid.',
            // Unicef
            unicef:
                'Een collectie vol tederheid om de actie UNICEF te steunen. ',
            // Mural
            mural:
                'Wenskaarten voor al de evenementen van het leven aangepast in verschillende maten, stijlen en afwerkingen. ',
            // Enfant
            dreamworks:
                'De sterren van studio DreamWorks in een spetterende collectie',
            'le-petit-monde-de-kali': 'De lieve wereld van Kali Stileman',
            // Claire Vision
            'de-profundis': 'Filosofisch en inspirerende kaarten.',
            'd-ici-et-d-ailleurs': 'Prachtige foto’s in panoramisch formaat.',
            'animaux-photos': 'Tederheid en humor voor deze collectie.',
            'cartes-textes':
                'Veel meer dan een simpele kaart, een tekst om bij na te denken.',
        },
        wrap: {
            animania: 'Een grappige dieren collectie',
            aquacolor:
                'Mooie aquarellen door herkende artiesten (Amy Sia, Nicola Gregory)',
            celeste: 'Sterren en planeten voor deze nieuwe collectie 2019',
            comics: 'Letters en woorden in een dynamische sfeer.',
            gentleman: 'Mannelijke motieven',
            gourmandise: 'Leuke tekeningen in felle kleuren.',
            'happy-kids': 'Naïeve tekeningen voor kinderen.',
            'les-unis': 'Subtiel en onvermijdelijk.',
            love: 'Harten in verschillende vormen en kleuren.',
            miniatures: 'Sober en minimalistisch',
            paradisio:
                'De natuur op zijn mooist voorgesteld door verschillende artiesten.(Gabby Malpas, Valérie Dumas)',
            pretty: 'Mooie harmonie in lichte pasteltinten ',
            spring: 'Bloemen en vlinders in talrijke kleuren.',
            'ultra-chic':
                'Harmonie van zwart, wit en pastel verhoogd met goud.',
        },
        item: {
            'mes-bougies-danniversaire':
                'Kaarsen in gekleurde lettervormen met grafische tekeningen. ',
            'mes-ballons-dannivresaire':
                'Gekleurde en bedrukte ballonnen met een voornaam in een leuke doos.',
            'mon-joli-stylo': 'Leuke pennen in een mooie doos',
            'mon-joli-petit-bijou':
                'Een gepersonaliseerde ketting met charms in een leuke gekleurde doos.',
            'mon-porte-cles': 'Een mooie sleutelhanger in een leuke doos',
        },
    },
    filter: {
        all: 'Alles',
        carterie: {
            enfant: 'Kinderen',
            general: 'Generaal',
            tendresse: 'Tederheid',
            fashion: 'Fashion',
            humour: 'Humor',
            mural: 'Muur',
            unicef: 'Unicef',
            'claire-vision': 'Claire Vision',
            finAnnee: 'Eindejaar',
        },
    },
    actu: {
        title: 'Momenteel bij Editor',
        '01-click': {
            title:
                'Click, een nieuwe kaart met een zeer actuele fotobehandeling versterkt met goudfolie en vernis.',
            image: 'Clik2.jpg',
        },
        '02-bracelet': {
            title:
                'Nieuwe gepersonaliseerde armbanden met gegraveerde medaillons en gekleurde parels.',
            image: 'IMG_9660.jpg',
        },
        '03-sparkle': {
            title:
                'Sparkle, een nieuwe fonkelende wenskaart met preeg en goudfolie bedrukt op glitter papier.',
            image: 'IMG_9939.jpg',
        },
        '04-paradisio': {
            title: 'Nieuw gamma verpakking 2019 Paradisio',
            image: 'IMG_0040.jpg',
        },
        '05-rouge': {
            title:
                'Zachte hoesjes, bedrukte brillen dozen, verguld goud, 4 grafische universums!',
            image: 'MG_9709-rouge.jpg',
        },
        '06-farce-attrape': {
            title:
                '“Farce et Attrape” de nieuwe collectie vol humor, liefde en diepe gedachten!',
            image: 'Farce&attrape.jpg',
        },
        '07-frankfort': {
            title:
                'Editie 2019 van de Stand Editor op het internationaal salon Paperworld in Frankfurt',
            image: 'GrEditor-Paperw19-5249.jpg',
        },
    },
    errors: {
        emptyField: 'Gelieve het veld in te vullen {field}',
        invalidEmail: 'Het formaat van het email adres is ongeldig',
        global: 'An error has occured',
    },
};
