export const SOURCE_CARDS = "giftCards";
export const SOURCE_WRAPS = "giftWrap";
export const SOURCE_ITEMS = "giftItems";

export const PAGE_HOME = "/";
export const PAGE_CARTERIE = "/gift-card/";
export const PAGE_EMBALLAGE = "/gift-wrap/";
export const PAGE_OBJET_CADEAU = "/gift-item/";
export const PAGE_GROUP = "/group/";
export const PAGE_CONTACT = "/contact/";
export const PAGE_ML = "/legal-notices";

export const routes = [
    {
        path: PAGE_HOME,
        name: "nav.home"
    },
    {
        path: PAGE_CARTERIE,
        name: "nav.card"
    },
    {
        path: PAGE_EMBALLAGE,
        name: "nav.wrap"
    },
    {
        path: PAGE_OBJET_CADEAU,
        name: "nav.item"
    },
    {
        path: PAGE_GROUP,
        name: "nav.group"
    },
    {
        path: "http://www.editor-recrute.com/",
        name: "nav.carrer",
        external: true
    },
    {
        path: PAGE_CONTACT,
        name: "nav.contact"
    },
    {
        path: PAGE_ML,
        name: "nav.ml"
    }
];
