/* eslint-disable jsx-a11y/no-onchange */
import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";

import "./SwitchLangBtn.styl";

const style = () => ({
    disabled: {
        backgroundColor: "rgba(0, 0, 0, .15)"
        // backgroundColor: palette.primary.dark
    },
    root: {
        color: "white"
    }
});

const SwitchLangBtn = ({ langs, classes }) => {
    const { disabled, root } = classes;
    return (
        <div className="swith-lang-bloc">
            {langs.map(lang => (
                <Button
                    className="button"
                    classes={{ disabled, root }}
                    disabled={lang.selected}
                    key={lang.langKey}
                >
                    <Link
                        component={GatsbyLink}
                        color="inherit"
                        className={lang.selected ? "active" : ""}
                        classes={{ root }}
                        to={lang.link}
                        title={lang.langValue}
                    >
                        {lang.langValue}
                    </Link>
                </Button>
            ))}
        </div>
    );
};

SwitchLangBtn.propTypes = {
    langs: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired
};

export default withStyles(style)(SwitchLangBtn);
