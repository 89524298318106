import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Nav.styl';

import LocalizedLink from '../../components/LocalizedLink';
import SwitchLangBtn from '../../components/SwitchLangBtn';
import { routes } from '../../../constants/router';
import siteConfig from '../../../../config/siteConfig';

const style = ({ palette }) => ({
    ListItem: {
        padding: '0',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
    },
    ListItemText: {
        color: 'white',
        padding: '0 16px',
        // textTransform: "uppercase"
        // fontWeight: 300
    },
    selected: {
        // backgroundColor: "rgba(0, 0, 0, 0.08)"
        backgroundColor: palette.primary.dark,
    },
});

const Nav = ({ close, classes, langs }) => (
    <div
        className='nav'
        role='presentation'
        onClick={close(false)}
        onKeyDown={close(false)}
    >
        <List id='menu-list' component='nav'>
            {routes.map((route, i) =>
                route.external ? (
                    <ListItem
                        button
                        className='menu-item'
                        classes={{
                            root: classes.ListItem,
                        }}
                        component='a'
                        key={route.name + i}
                        target='_blank'
                        rel='noreferrer noopener'
                        href={route.path}
                    >
                        <ListItemText
                            primary={<FormattedMessage id={route.name} />}
                            classes={{
                                primary: classes.ListItemText,
                            }}
                        />
                    </ListItem>
                ) : (
                    <ListItem
                        button
                        className='menu-item'
                        key={route.name + i}
                        classes={{
                            root: classes.ListItem,
                            selected: classes.selected,
                        }}
                    >
                        <LocalizedLink
                            key={route.name + i}
                            to={route.path}
                            activeClassName={'active-link'}
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <ListItemText
                                primary={<FormattedMessage id={route.name} />}
                                classes={{
                                    primary: classes.ListItemText,
                                }}
                            />
                        </LocalizedLink>
                    </ListItem>
                ),
            )}
        </List>
        <Divider style={{ margin: '24px 0 16px' }} />
        <SwitchLangBtn langs={langs} />
        <Divider style={{ margin: '24px 0 16px' }} />
        <div className='social-icons-bloc'>
            <ul className='icons-list'>
                {Object.values(siteConfig.social).map((network, i) => (
                    <li key={network.icon + i} className='icon-social'>
                        <a
                            href={network.url}
                            target='_blank'
                            rel='noreferrer noopener'
                        >
                            <FontAwesomeIcon icon={['fab', network.icon]} />
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    </div>
);

Nav.propTypes = {
    close: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    langs: PropTypes.array.isRequired,
};

export default withStyles(style)(Nav);
