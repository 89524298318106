const activeEnv = process.env.MODE || process.env.NODE_ENV || `development`;

// eslint-disable-next-line import/no-extraneous-dependencies
require(`dotenv`).config({
    path: `.env.${activeEnv}`,
});

module.exports = {
    // SEO
    title: `pages.meta.title`,
    titleAlt: `pages.meta.titleAlt`,
    description: `pages.meta.description`,
    pathPrefix: `/`,
    siteUrl: process.env.GATSBY_SITE_URL || `http://localhost:8000`,
    apiUrl: process.env.GATSBY_API_URL,
    icon: `src/images/icons/editor-icon2.png`,
    keywords: [],
    googleSiteVerification: process.env.GATSBY_GOOGLE_SITE_VERIFICATION || ``,
    reCaptchaKey: process.env.GATSBY_RECAPTCHA_KEY || '',
    // manifest config
    langs: {
        all: ['fr', 'en', 'nl'],
        default: {
            lang: `fr`,
            name: `Groupe Editor`,
            short_name: `GroupeEditor`,
            description: `Leader européen en carterie, emballage cadeau, papeterie et objets cadeaux`,
        },
        others: [
            {
                lang: `en`,
                name: `Groupe Editor`,
                short_name: `GroupeEditor`,
                description: `The market leader in continental Europe for cards and gift wrap`,
                start_url: `/en/`,
            },
            {
                lang: `nl`,
                name: `Groep Editor`,
                short_name: `GroepEditor`,
                description: `Europese leader in wenskaarten, verpakking, papeterie en geschenkartikelen`,
                start_url: `/nl/`,
            },
        ],
    },

    backgroundColor: `#d31548`,
    themeColor: `#d31548`,
    // Social
    social: {
        facebook: {
            url: `https://www.facebook.com/groupe.editor.job/`,
            icon: 'facebook-f',
        },
        instagram: {
            url: 'https://www.instagram.com/editor.giving.joy/',
            icon: 'instagram',
        },
        youtube: {
            url: 'https://www.youtube.com/channel/UCGSrMTb7PkYIjG-F8SWnFSA',
            icon: 'youtube',
        },
        linkedin: {
            url: 'http://fr.linkedin.com/company/groupe-editor',
            icon: 'linkedin-in',
        },
    },
    socialShare: {},
    // Author
    authorName: `Jimmy Beldone`,
    authorEmail: `dev.jimmy.beldone@gmail.com`,
    authorLocation: `Lyon, France`,
    authorAvatar: ``,
    authorDescription: ``,
    authorTwitter: `@jimmy_dev`,
    authorGithub: `https://github.com/JimmyBeldone/`,
    copyright: `Copyright © ${new Date().getFullYear()}`,
};
