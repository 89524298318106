export default {
    headerTitle: 'Wettelijke vermeldingen',
    metaDescription:
        'Contacteer ons voor de wettelijke vermeldingen van Editor',
    title: 'Wettelijke vermeldingen',
    section: {
        title: 'groupe-editor.com',
        intro:
            'Krachtens artikel 6 van de wet nr. 2004-575 van 21 juni 2004 voor het vertrouwen in de digitale economie, wordt aan de gebruikers van de site {site} de identiteit van de verschillende interveniënten kenbaar gemaakt in het kader van de realisatie en de opvolging ervan:',
        editor: {
            title: 'Eigenaar van de site',
            p:
                'Beldone {br} Jimmy Gaulet {br} 5 rue Longue{br} 69001 LYON {br} France {br} dev.jimmy.beldone@gmail.com {br} SIRET : 79122095700025',
        },
        owner: {
            title: 'Uitgever van de site',
            p:
                'GROUPE EDITOR {br} S.A.S met een kapitaal van 598 700 Euros {br}  ingeschreven in het Handels- en Vennootschapsregister van Aix-en-Provence onder het nummer B 380 471 540. {br} Adres van de maatschappelijke zetel: 355, rue Georges Claude {br} 13799 Aix-en-Provence Cedex 3 {br} Frankrijk {br} Telefoon : 04 42 39 50 00 {br}Verantwoordelijke voor de publicatie: Dienst Aanwerving Editor',
        },
        hosting: {
            title: 'Hosting van de site',
            p:
                'ITIKA GROUPE, {br} SAS met een kapitaal van 26 000 Euros {br} ingeschreven in het Handels- en Vennootschapsregister van Marseille onder het nummer  532 137 452. {br}Adres van de maatschappelijke zetel: 3 place de la Rotonde {br} 13014 Marseille {br}Telefoon:  04 91 42 53 41',
        },
        cgu: {
            title: 'Algemene gebruiksvoorwaarden van de site {site}',
            p:
                'Elke persoon die inlogt op de site {site} verbindt zich ertoe de onderhavige voorwaarden na te leven die, voor sommige diensten, aangevuld kunnen worden door bijzondere voorwaarden.',
        },
        access: {
            title: 'Toegang tot de site',
            p:
                'De site {site} is 24 uur op 24 en 7 dagen op 7 toegankelijk voor de Gebruiker, behoudens andersluidende bepalingen. De verantwoordelijkheid van EDITOR kan niet worden ingeroepen tengevolge van een technische onbeschikbaarheid van de verbinding, ongeacht of deze te wijten is aan een geval van overmacht, aan een onderhoud, aan een update, aan een wijziging van de Site, aan een tussenkomst van de host, aan een interne of externe staking, aan een netwerkdefect, aan een elektrische stroomonderbreking of ook aan een verkeerde configuratie of een verkeerd gebruik van de computer door de Gebruiker.',
        },
        copyright: {
            title: 'Intellectuele eigendom',
            p1:
                'Alle elementen van de site {site}, en dan met name de teksten, presentaties, illustraties, foto’s, documenten die kunnen worden gedownload, handelsmerken (die neergelegd zijn door Editor of door een van zijn partners), boomstructuren en vormgevingen, met uitzondering van openbare documenten en bijkomende verduidelijkingen, zijn de exclusieve eigendom van EDITOR of zijn partners.',
            p2:
                'Uit hoofde hiervan zijn de volledige of gedeeltelijke representaties, reproducties, bewerkingen, verspreidingen en herverspreidingen verboden overeenkomstig de bepalingen van artikel L122-4 van de Wet op de Intellectuele Eigendom. Elke persoon die hiertoe overgaat zonder voorafgaande en uitdrukkelijke toestemming van de eigenaar van deze rechten, zal veroordeeld worden voor imitatie volgens artikelen L335-2 en volgende van deze zelfde wet.',
            p3:
                'Bovendien is de weergave, reproductie, overname, verspreiding of verdere verspreiding, geheel of gedeeltelijk, van het gegevensbestand beschikbaar op de site {site} verboden krachtens de bepalingen van de wet nr. 98-536 van 1 juli 1998 met betrekking tot de juridische bescherming van gegevensbestanden.',
            p4:
                'In ieder geval moet op elke toegestane kopie van de gehele of gedeeltelijke inhoud van de Site de vermelding “Copyright 2016 – EDITOR – alle rechten voorbehouden” staan.',
        },
        links: {
            title: 'Hypertekstlinks',
            p1:
                'Iedere webmaster die op zijn website een link wil plaatsen naar de website {site} is verplicht hiervoor de voorafgaande en uitdrukkelijke toestemming te vragen door het sturen van een e-mail naar het hiertoe voorziene adres op de site {site} in de rubriek Contact. In ieder geval stemt de webmaaster die een hypertekstlink plaatst, ermee in om geen “deep linking” te gebruiken, een techniek waarmee pagina’s op de site {site} worden ingebed op pagina’s van de Site van de webmaster.',
            p2:
                'De Gebruiker die via de hypertekstlink op de site {site} wordt doorgestuurd naar een pagina van een derde partij erkent dat EDITOR geen invloed heeft op de inhoud van de sites naar welke de Gebruiker wordt doorgestuurd. Bijgevolg kan EDITOR in geen geval aansprakelijk worden gesteld voor eventuele directe of indirecte schade als gevolg van het gebruik van deze sites.',
        },
        photo: {
            title: 'Credit foto’s',
            p: 'Photothèque Editor – Marion Cau',
        },
        userData: {
            title: 'Persoonsgegevens',
            collected: {
                title: 'Ingezamelde persoonsgegevens',
                p:
                    'Indien de Gebruiker de site {site} raadpleegt, is het mogelijk dat hij persoonlijke informatie die op hem betrekking heeft, moet meedelen. Deze gegevens worden ingezameld enkel ten behoeve van de diensten die worden aangeboden op de site {site} en mogen niet worden ingezameld of gepubliceerd zonder medeweten van de Gebruiker die deze vrijwillig aanlevert door middel van een onlineformulier. Aan de Gebruiker wordt bijgevolg het verplichte of facultatieve karakter verduidelijkt met betrekking tot de verschaffen informatie.',
            },
            access: {
                title: 'Recht op toegang',
                p1:
                    'Overeenkomstig de zogenaamde wet “Informatica en Vrijheden” (artikel 34 van de wet nr. 78-17 van 6 januari 1978) beschikt de Gebruiker van de Site {site} over een recht tot wijziging van de ingezamelde persoonsgegevens die op hem betrekking hebben.',
                p2:
                    'In dit geval kan de Gebruiker een brief sturen naar EDITOR en wel op het volgende adres: Groupe Editor – Dienst Aanwerving – 355, rue Georges Claude – CS 90385 – 13799 Aix-en-Provence Cedex 3, met vermelding van zijn naam of zijn firmanaam, zijn fysieke en/of elektronische gegevens, alsook, in voorkomend geval, de referentie waarover hij zou beschikken als lid van de site {site}.',
                p3:
                    'De Site stelt aan de Gebruiker voor om informatie in RSS-feed te ontvangen door te klikken op het overeenkomstige icoon. In dit geval wordt het IP-adres van de Gebruiker enkel met dit doel ingezameld en kan het op geen enkel moment gebruikt worden voor een ander doel.',
            },
            cookie: {
                title:
                    'Gebruik van cookies met het oog op stastieken over de bezoekfrequentie van de site',
                p1:
                    'Het IP-adres van de Gebruiker van de Site {site} zal worden ingezameld om statistische studies en analyses uit te voeren over de bezoekfrequentie van de Site. Deze informatie is bestemd voor de informatica- en marketingdiensten van {site} of van zijn partners.',
                p2:
                    'De Gebruiker kan het gebruik van cookies deactiveren door de juiste parameters van zijn browser te selecteren. Door een dergelijke deactivering kan het gebruik van bepaalde functionaliteiten van de site {site} evenwel worden verhinderd. Door deze site te gebruiken, stemt de Gebruiker er uitdrukkelijk mee in dat zijn persoonsgegevens worden verwerkt door Google overeenkomstig de hierboven beschreven voorwaarden en doeleinden. De Gebruiker kan zich verzetten tegen de registratie van cookies door zijn browser op de volgende manier te configureren:',
                moz: {
                    title: 'Voor Mozilla Firefox',
                    l1: 'Kies het menu “Tools” en daarna “Opties”',
                    l2: 'Klik op het tabblad “Privéleven”',
                    l3:
                        'Selecteer in het menu “Cookies” de opties die passen of vink het vakje “Verboden voor website om me te volgen”.',
                },
                ie: {
                    title: 'Voor Microsoft Internet Explorer',
                    l1: 'Kies het menu “Extra” en daarna “Internetopties”',
                    l2: 'Klik op het tabblad “Privacy”',
                    l3:
                        'Selecteer het gewenste niveau met behulp van de cursor.',
                },
                opera: {
                    title: 'Voor Opéra 6.0 en hoger',
                    l1: 'Kies het menu “Bestand” en daarna “Voorkeuren”',
                    l2: 'Klik op het tabblad “Privacy”',
                },
                chrome: {
                    title: 'Voor Google Chrome',
                    l1:
                        'Klik in de toolbar van uw browser op het icoon dat een Engelse sleutel voorstelt',
                    l2: 'Selecteer het tabblad “Parameters”',
                    l3: 'Klik op “Geavanceerde parameters weergeven”',
                    l4:
                        'In de sectie “Privacy”, klik op “Parameters betreffende de inhoud”',
                    l5:
                        'Ga naar de sectie “Cookies” om de parameters te wijzigen.',
                },
                p3:
                    'Voor meer informatie over cookies, kan u ook terecht op de site van de CNIL: {site}',
            },
            socials: {
                title: 'Sociale netwerken',
                p:
                    'Onze site bevat karakteristieken en knoppen die verbonden zijn aan sociale netwerken en die aangeleverd worden door derden, zoals Facebook, LinkedIn, Viadeo, Youtube (hierna “Functionaliteiten” genoemd). Deze Functionaliteiten kunnen bijvoorbeeld uw IP-adres en de pagina’s die u bezoekt op onze site inzamelen en hiervoor een cookie aanmaken om naar behoren te werken. Via deze cookie kunnen deze sites van derden de bovenstaande gegevens ook koppelen aan uw gebruikersprofiel. Deze Functionaliteiten kunnen gehost worden door een derde site of rechtstreeks op onze site. Uw interacties met deze Functionaliteiten zijn onderworpen aan de vertrouwelijkheidsregels van de desbetreffende site.',
            },
            personnal: {
                title: 'Persoonsgegevens',
                p1:
                    'De persoonsgegevens die worden ingezameld op de site genieten van de bescherming van de wet “Informatica en Vrijheden” nr. 78-17 van 6 januari 1978, zoals gewijzigd in 2004.',
                p2:
                    'U geniet van een recht tot toegang, verbetering, verzet tot mededeling en schrapping op eenvoudig verzoek aan Groupe Editor – Dienst Aanwerving – 355, rue Georges Claude – CS 90385 – 13799 Aix-en-Provence Cedex 3.',
            },
            right: {
                title: 'Toepasselijk recht',
                p:
                    'De Site en de onderhavige Algemene Gebruiksvoorwaarden zijn onderworpen aan de bepalingen van het Franse recht.',
            },
        },
    },
};
