export default {
    headerTitle: 'Legal notices',
    metaDescription: "Editor's legal notices, contact us",
    title: 'Legal notices',
    section: {
        title: 'groupe-editor.com',
        intro:
            'Pursuant to Article 6 of Law No. 2004-547 of 21 June 2004 to build confidence in the digital economy, we inform users of {site} of the identity of the various parties involved in creating and monitoring the website:',
        editor: {
            title: 'Site editor',
            p:
                'Beldone {br} Jimmy Gaulet {br} 5 rue Longue{br} 69001 LYON {br} France {br} dev.jimmy.beldone@gmail.com {br} SIRET : 79122095700025',
        },
        owner: {
            title: 'Site owner',
            p:
                'GROUPE EDITOR {br} S.A.S with capital of 598 700 Euros {br} registered on the Aix-en-Provence Commercial Register under number B 380 471 540. {br} Address of head offices: 355, rue Georges Claude {br} 13799 Aix-en-Provence Cedex 3 {br} France {br} Téléphone : 04 42 39 50 00 {br}Publication Manager: Editor Recruitment Department',
        },
        hosting: {
            title: 'Site hosting service',
            p:
                'ITIKA GROUPE, {br} SAS with capital of 26 000 Euros {br} registered on the Marseille Commercial Register under number  532 137 452. {br}Address of head offices:  3 place de la Rotonde {br} 13014 Marseille {br}Téléphone : 04 91 42 53 41',
        },
        cgu: {
            title: '{site} Terms of use',
            p:
                'Any person who accesses {site} agrees to comply with the present terms of use, which for some services may be supplemented by special conditions.',
        },
        access: {
            title: 'Accessing the site',
            p:
                'Users can access {site} 24/7, unless otherwise specified. EDITOR cannot be held liable if the connection is unavailable for technical reasons, whether due to force majeure, maintenance, updates, modifications to the Site, interventions by the web hosting service, internal or external strikes, network failures, power cuts or improper configuration or use of the computer by the User.',
        },
        copyright: {
            title: 'Intellectual property',
            p1:
                'Excluding public documents or additional specifications, all content on the Website {site}, in particular the text, presentations, illustrations, photos, downloadable documents, trade names (registered by Editor or one of its partners), tree diagrams and layouts are the exclusive property of EDITOR or its partners.',
            p2:
                'As such, representations, reproductions, embedding, diffusion and reruns, partial and total, are forbidden in accordance with Article L122-4 of the Intellectual Property Code. Any person doing so without proof of express prior authorisation from the holder of these rights will incur penalties relating to copyright infringement under articles L335-2 et seq. of the same Code.',
            p3:
                'Additionally, representations, reproductions, embedding, diffusion and reruns, partial and total, of databases on the {site} website are forbidden in accordance with Law No. 98-536 of 1 July 1998 on the legal protection of databases.',
            p4:
                'In any case, the words “Copyright 2016 – EDITOR – all rights reserved” must appear on any authorised copy of all or part of the Site’s content.',
        },
        links: {
            title: 'Hyperlinks',
            p1:
                'Any webmaster who would like to establish a link from their site to www.editor -recrute.com must obtain express prior authorisation by sending an email to the address provided on {site} in the Contact section. In any case, webmasters who create a hyperlink agree not to use the deep linking method, whereby the pages of {site} are embedded within the pages of the webmaster’s site.',
            p2:
                'Users redirected to a third-party webpage by a hyperlink on {site} acknowledge that these websites are not under the control of EDITOR. Consequently, EDITOR cannot be held liable for any direct or indirect damage caused by using these sites.',
        },
        photo: {
            title: 'Photo credits',
            p: 'Editor photo library – Marion Cau',
        },
        userData: {
            title: 'Personal data',
            collected: {
                title: 'Personal data collected',
                p:
                    'When visiting {site} Users may communicate personal information. This data is only collected for the requirements of the services offered on {site} and cannot be collected or published without the Users’ knowledge. This data is provided voluntarily by the Users via an online form. In this form, Users are indicated whether the information they are providing is mandatory or optional.',
            },
            access: {
                title: 'Right of access',
                p1:
                    'In accordance with the “Data Protection” Act (Article 34 of Law No. 78-17 of 6 January 1978), Users of the {site} Site have the right to modify any personal data collected relating to them.',
                p2:
                    'In this case, Users must send a letter to EDITOR at the following address: Groupe Editor – Service Recrutement – 355, rue Georges Claude – CS 90385 – 13799 Aix-en-Provence Cedex 3 FRANCE, indicating their name or corporate name, their physical and/or electronic addresses and, when appropriate, their reference as a member of {site}.',
                p3:
                    'The Site allows Users to receive information as an RSS feed by clicking on the corresponding icon. In this case, the User’s IP address is collected for this purpose only and cannot at any moment be used for any other purpose.',
            },
            cookie: {
                title: 'Use of cookies for site traffic statistics',
                p1:
                    'The IP address of Users of the {site} Site will be collected to carry out statistical studies and analysis of the Site’s traffic. This information is used by {site} or its partners’ IT and Marketing departments.',
                p2:
                    'Users can disable the use of cookies by selecting the required settings in their browser. However, disabling cookies may prevent the use of some of the Site’s functions. By using this site, Users expressly accept that their personal data will be processed by Google in the conditions and for the purposes described above. Users can prevent cookies from being stored by changing their browser settings in the following way:',
                moz: {
                    title: 'For Mozilla Firefox',
                    l1: 'Click on the “Tools” menu then “Options”',
                    l2: 'Click on the “Privacy” panel',
                    l3:
                        'Find the “Cookies” menu and select the appropriate options or tick to box “Tell sites I do not want to be tracked”',
                },
                ie: {
                    title: 'For Microsoft Internet Explorer',
                    l1: 'Click on the “Tools” menu then “Internet Options”',
                    l2: 'Click on the “Privacy” tab',
                    l3: 'Select the level required using the slider.',
                },
                opera: {
                    title: 'For Opéra 6.0 and higher',
                    l1: 'Click on the “File” menu then “Preferences”',
                    l2: 'Click on the “Privacy and security” tab',
                },
                chrome: {
                    title: 'For Google Chrome',
                    l1:
                        'Click on the icon that represents a spanner in the browser’s toolbar',
                    l2: 'Select the “Settings” tab',
                    l3: 'Click on “Show advanced settings”',
                    l4:
                        'Under the “Privacy” section, click on “Content Settings”',
                    l5: 'Go to the “Cookies” section to modify the settings.',
                },
                p3:
                    'For more information about cookies, you can also go to the French Data Protection Authority’s website (in French): {site}',
            },
            socials: {
                title: 'Social networks',
                p:
                    'Our site includes characteristics and buttons linked to social networks supplied by third parties like Facebook, LinkedIn, Viadeo, YouTube (hereinafter called “Functionalities”). These Functionalities can for example collect your IP address and the pages that you visit on our site, and generate to that effect a cookie to work correctly. This cookie can also enable these third party sites to link data below with your user profile. These Functionalities can be hosted on a third party site or directly on our site. Your interactions with these Functionalities are governed by the Privacy Policy of the site in question.',
            },
            personnal: {
                title: 'Personal data',
                p1:
                    'Personal data collected on the site is protected by the “Data Protection” Act No. 78-17 of 6 January 1978 modified in 2004.',
                p2:
                    'You have the right to access, modify, block onward transmission and delete this data on simple request to Groupe Editor – Service Recrutement – 355, rue Georges Claude – CS 90385 – 13799 Aix-en-Provence Cedex 3 France.',
            },
            right: {
                title: 'Applicable law',
                p:
                    'The Site and the present Terms of Use are subject to the provisions of French law.',
            },
        },
    },
};
