const locales = require(`../constants/locales`);
const slugs = require(`../lang/slugs.json`);

const slugExist = (originalPath, lang) => {
    if (originalPath === `/`) {
        return originalPath;
    } else {
        return slugs[lang][originalPath] !== undefined
            ? `/${slugs[lang][originalPath]}/`
            : `/${originalPath}/`;
    }
};

exports.getSlug = (path, lang) => {
    const pathItems = path !== `/` ? path.split(`/`) : [`/`];

    const explosedSlug = pathItems.reduce((acc, curr) => {
        if (curr !== ``) acc.push(curr);
        return acc;
    }, []);

    let localizedPath;
    let originalPath;

    if (explosedSlug.length > 1) {
        const slug = explosedSlug.pop();
        originalPath = explosedSlug.pop();

        localizedPath = locales[lang].default
            ? slugExist(originalPath, lang) + slug
            : `/` + locales[lang].path + slugExist(originalPath, lang) + slug;
    } else {
        originalPath = explosedSlug.pop();

        localizedPath = locales[lang].default
            ? slugExist(originalPath, lang)
            : `/` + locales[lang].path + slugExist(originalPath, lang);
    }

    return localizedPath;
};
