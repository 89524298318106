module.exports = {
    fr: {
        path: 'fr',
        locale: 'FR',
        territory: `fr_FR`,
        default: true,
    },
    en: {
        path: 'en',
        territory: `en_US`,
        locale: 'EN',
    },
    nl: {
        path: 'nl',
        territory: `be_NL`,
        locale: 'NL',
    },
};
