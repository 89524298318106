export default {
    headerTitle: 'Mentions Légales',
    metaDescription: 'Les mentions légales d’Editor, contactez-nous',
    title: 'Mentions Légales',
    section: {
        title: 'groupe-editor.com',
        intro:
            'En vertu de l’article 6 de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, il est précisé aux utilisateurs du site {site} l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi :',
        editor: {
            title: 'Editeur du site',
            p:
                'Beldone {br} Jimmy Gaulet {br} 5 rue Longue{br} 69001 LYON {br} France {br} dev.jimmy.beldone@gmail.com {br} SIRET : 79122095700025',
        },
        owner: {
            title: 'Propriétaire du site',
            p:
                'GROUPE EDITOR {br} S.A.S au capital de 598 700 Euros {br} immatriculée au RCS d’Aix-en-Provence sous le numéro B 380 471 540. {br} Adresse du siège social : 355, rue Georges Claude {br} 13799 Aix-en-Provence Cedex 3 {br} France {br} Téléphone : 04 42 39 50 00 {br}Responsable de publication : Service Recrutement Editor',
        },
        hosting: {
            title: 'Hébergement du site',
            p:
                'ITIKA GROUPE, {br} SAS au capital de 26 000 Euros {br} immatriculée au RCS de Marseille sous le numéro 532 137 452. {br}Adresse du siège social : 3 place de la Rotonde {br} 13014 Marseille {br}Téléphone : 04 91 42 53 41',
        },
        cgu: {
            title: 'Conditions générales d’utilisation du site {site}',
            p:
                'Toute personne qui accède au site {site} s’engage à respecter les présentes conditions d’utilisation qui pourront, pour certains services, être complétées par des conditions particulières.',
        },
        access: {
            title: 'Accès au site',
            p:
                'Le Site {site} est accessible à l’Utilisateur 24h/24, 7j/7, sauf précisions contraires. La responsabilité d’EDITOR ne peut être engagée en raison d’une indisponibilité technique de la connexion, qu’elle soit due notamment à un cas de force majeure, à une maintenance, à une mise à jour, à une modification du Site, à une intervention de l’hébergeur, à une grève interne ou externe, à une panne de réseau, à une coupure d’alimentation électrique ou encore à une mauvaise configuration ou utilisation de l’ordinateur par l’Utilisateur.',
        },
        copyright: {
            title: 'Propriété intellectuelle',
            p1:
                'La totalité des éléments du site {site}, notamment les textes, présentations, illustrations, photographies, documents téléchargeables, marques commerciales (déposées par Editor ou l’un de ses partenaires), arborescences et mises en forme sont, sauf documents publics et précisions complémentaires, la propriété exclusive d’EDITOR ou de ses partenaires.',
            p2:
                'À ce titre, leurs représentations, reproductions, imbrications, diffusions et rediffusions, partielles ou totales, sont interdites conformément aux dispositions de l’article L122-4 du Code de la propriété intellectuelle. Toute personne y procédant sans pouvoir justifier d’une autorisation préalable et expresse du détenteur de ces droits encourt les peines relatives au délit de contrefaçon prévues aux articles L335-2 et suivants du même Code.',
            p3:
                'En outre, les représentations, reproductions, imbrications, diffusions et rediffusions, partielles ou totales, des bases de données contenues sur le site {site} sont interdites en vertu des dispositions de la loi n°98-536 du 1{supp} juillet 1998 relative à la protection juridique des bases de données.',
            p4:
                'En tout état de cause, une mention « Copyright 2016 – EDITOR – tous droits réservés » devra figurer sur toute copie autorisée de tout ou partie du contenu du Site.',
        },
        links: {
            title: 'Liens hypertextes',
            p1:
                'Tout webmaster qui souhaiterait établir un lien à partir de son site vers le site {site} doit obtenir une autorisation expresse préalable en envoyant un courrier électronique à l’adresse prévue à cet effet sur le site {site} dans la rubrique Contact. En tout état de cause, le webmaster qui créerait un lien hypertexte s’engage à ne pas utiliser la technique du lien profond (« deep linking ») selon laquelle les pages du site {site} sont imbriquées à l’intérieur des pages du Site du webmaster.',
            p2:
                'L’Utilisateur qui serait redirigé vers une tierce page Internet par l’intermédiaire d’un lien hypertexte figurant sur le site {site} reconnaît qu’EDITOR ne maîtrise pas le contenu des sites de redirection. En conséquence, EDITOR ne pourra en aucun cas être tenu responsable d’éventuels dommages directs ou indirects du fait de l’utilisation de ces sites.',
        },
        photo: {
            title: 'Crédit photos',
            p: 'Photothèque Editor – Marion Cau',
        },
        userData: {
            title: 'Données à caractère personnel',
            collected: {
                title: 'Données personnelles collectées',
                p:
                    'En consultant le site {site} l’Utilisateur est susceptible de communiquer des informations personnelles le concernant. Ces données ne sont collectées que pour le besoin des services proposés par le site {site} et ne sauraient être collectées ou publiées à l’insu de l’Utilisateur, qui les fournit volontairement par le biais d’un formulaire en ligne. Il est alors précisé à l’Utilisateur le caractère obligatoire ou facultatif des informations à donner.',
            },
            access: {
                title: 'Droit d’accès',
                p1:
                    'Conformément à la loi dite « Informatique et Libertés » (article 34 de la loi n°78-17 du 6 janvier 1978), l’Utilisateur du Site {site} dispose d’un droit de modification des données personnelles collectées le concernant.',
                p2:
                    'Dans ce cas, l’Utilisateur peut envoyer à EDITOR un courrier postal à l’adresse suivante : Groupe Editor – Service Recrutement – 355, rue Georges Claude – CS 90385 – 13799 Aix-en-Provence Cedex 3, en indiquant son nom ou sa raison sociale, ses coordonnées physiques et/ou électroniques ainsi que, le cas échéant, la référence dont il disposerait en tant que membre du site {site}',
                p3:
                    'Le Site propose à l’Utilisateur de recevoir des informations en flux RSS en cliquant sur l’icône correspondante. Dans ce cas, l’adresse IP de l’Utilisateur est collectée dans ce seul but et ne pourra à aucun moment être utilisée pour une autre fin.',
            },
            cookie: {
                title:
                    'Utilisation des cookies aux fins statistiques de fréquentation du site',
                p1:
                    'L’adresse IP de l’Utilisateur du Site {site} sera recueillie afin d’effectuer des études et analyses statistiques de fréquentation du Site. Ces informations sont destinées aux services informatiques et marketing de {site} ou de ses partenaires.',
                p2:
                    'L’Utilisateur peut désactiver l’utilisation des cookies en sélectionnant les paramètres appropriés de son navigateur. Cependant, une telle désactivation est susceptible d’empêcher l’utilisation de certaines fonctionnalités du site {site}. En utilisant ce site, l’Utilisateur consent expressément au traitement de ses données personnelles par Google dans les conditions et pour les finalités décrites ci-dessus. L’Utilisateur peut s’opposer à l’enregistrement de cookies en configurant son navigateur de la manière suivante :',
                moz: {
                    title: 'Pour Mozilla Firefox',
                    l1: 'Choisir le menu « Outils » puis « Options »',
                    l2: 'Cliquer sur l’onglet « Vie privée »',
                    l3:
                        'Repérer le menu « Cookies » et sélectionner les options qui conviennent ou cocher la case « Interdire aux sites web de me pister ».',
                },
                ie: {
                    title: 'Pour Microsoft Internet Explorer',
                    l1: 'Choisir le menu « Outils » puis « Options Internet »',
                    l2: 'Cliquer sur l’onglet « Confidentialité »',
                    l3: 'Sélectionner le niveau souhaité à l’aide du curseur.',
                },
                opera: {
                    title: 'Pour Opéra 6.0 et au-delà',
                    l1: 'Choisir le menu « Fichier » puis « Préférences »',
                    l2: 'Cliquer sur l’onglet « Vie privée ».',
                },
                chrome: {
                    title: 'Pour Google Chrome',
                    l1:
                        'Cliquer sur l’icône représentant une clé à molette dans la barre d’outils du navigateur',
                    l2: 'Sélectionner l’onglet « Paramètres »',
                    l3: 'Cliquer sur « Afficher les paramètres avancés »',
                    l4:
                        'Dans la section « Confidentialité », cliquer sur « Paramètres de contenu »',
                    l5:
                        'Aller à la section « Cookies » pour modifier les paramètres.',
                },
                p3:
                    'Pour plus d’informations sur les cookies, vous pouvez également visiter le site de la CNIL: {site}',
            },
            socials: {
                title: 'Réseaux sociaux',
                p:
                    'Notre site inclut des caractéristiques et boutons liés à des réseaux sociaux et fournis par des tiers tels que Facebook, Linkedin, Viadeo, Youtube (nommés ci-après « Fonctionnalités »). Ces Fonctionnalités peuvent par exemple collecter votre adresse IP et les pages que vous visitez sur notre site, et générer pour cela un cookie pour fonctionner convenablement. Ce cookie pourrait également permettre à ces sites tiers de lier les données ci-dessus avec votre profil d’utilisateur. Ces Fonctionnalités peuvent être hébergées par un site tiers ou directement sur notre site. Vos interactions avec ces Fonctionnalités sont régies par les Règles de confidentialité du site concerné.',
            },
            personnal: {
                title: 'Données à caractère personnel',
                p1:
                    'Les données personnelles recueillies sur le site bénéficient de la protection de la loi « Informatique et Libertés » n°78-17 du 6 modifiée en 2004.',
                p2:
                    'Vous bénéficiez d’un droit d’accès, de rectification, d’opposition à communication et de suppression sur simple demande à Groupe Editor – Service Recrutement – 355, rue Georges Claude – CS 90385 – 13799 Aix-en-Provence Cedex 3',
            },
            right: {
                title: 'Droit applicable',
                p:
                    'Le Site et les présentes Conditions Générales d’Utilisation sont soumis aux dispositions du droit français.',
            },
        },
    },
};
